import React, { useState } from 'react';
import { Button, Modal } from 'flowbite-react';
import { toast } from 'react-toastify';

const RegenerateModal = ({ 
    isOpen, 
    onClose, 
    onRegenerate, 
    mainTopic, 
    selected, 
    type 
}) => {
    const [userPrompt, setUserPrompt] = useState('');

    const handleRegenerate = () => {
        onRegenerate(userPrompt);
        onClose();
    };

    return (
        <Modal show={isOpen} onClose={onClose}>
            <Modal.Header>Regenerate Content</Modal.Header>
            <Modal.Body>
                <div className="space-y-4">
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Regenerate content for <span className="font-bold">{selected}</span> in {mainTopic}
                    </p>
                    <textarea 
                        rows={4} 
                        className="w-full p-2 border rounded dark:bg-gray-700 dark:text-white"
                        placeholder={`Optional: Provide a specific prompt to customize regeneration for ${selected}`}
                        value={userPrompt}
                        onChange={(e) => setUserPrompt(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" onClick={handleRegenerate} className='bg-black'>
                    Regenerate
                </Button>
                <Button color="gray" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RegenerateModal;