import React, { useState } from 'react';
import { Modal, Button, TextInput } from 'flowbite-react';
import { AiOutlineLoading } from 'react-icons/ai';
import axios from 'axios';

const RegenerateTopicDialog = ({ topic, topicIndex, jsonData, mainTopic, type, onUpdateTopic, serverURL }) => {
  const [open, setOpen] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [processing, setProcessing] = useState(false);

  const handleRegenerate = async () => {
    setProcessing(true);
    const systemPrompt = `Generate a single topic and its subtopics for ${mainTopic.toLowerCase()}, everything in single line. The topic should Strictly be about ${jsonData[mainTopic][topicIndex].title.toLowerCase()}. Consider this additional context from user: ${userPrompt}. Strictly Keep theory, youtube, image field empty. Generate in the form of JSON in this format {
      "${mainTopic.toLowerCase()}": [
        {
          "title": "${jsonData[mainTopic][topicIndex].title}",
          "subtopics": [
            {
              "title": "Sub Topic Title",
              "theory": "",
              "youtube": "",
              "image": "",
              "done": false
            }
          ]
        }
      ]
    }`;

    const dataToSend = {
      prompt: systemPrompt,
    };

    try {
      const res = await axios.post(serverURL + '/api/prompt', dataToSend);
      const generatedText = res.data.generatedText;
      const cleanedJsonString = generatedText.replace(/```json/g, '').replace(/```/g, '');
      const parsedJson = JSON.parse(cleanedJsonString);
      
      // Update only the specific topic and its subtopics
      const newJsonData = { ...jsonData };
      newJsonData[mainTopic][topicIndex] = parsedJson[mainTopic][0];
      
      onUpdateTopic(newJsonData);
      setProcessing(false);
      setOpen(false);
      setUserPrompt(''); // Reset the prompt input
    } catch (error) {
      console.error('Error regenerating topic:', error);
      setProcessing(false);
    }
  };

  return (
    <>
      {/* <AiOutlineReload 
        className="text-xl text-white dark:text-black mr-2 cursor-pointer hover:opacity-75"
        onClick={() => setOpen(true)}
      /> */}
      <div onClick={() => setOpen(true)} className="text-xl text-white dark:text-black cursor-pointer hover:opacity-75">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="2rem" height="2rem" viewBox="0 0 50 50">
        <path d="M12,17c0.8-4.2,1.9-5.3,6.1-6.1c0.5-0.1,0.8-0.5,0.8-1s-0.3-0.9-0.8-1C13.9,8.1,12.8,7,12,2.8C11.9,2.3,11.5,2,11,2 c-0.5,0-0.9,0.3-1,0.8C9.2,7,8.1,8.1,3.9,8.9C3.5,9,3.1,9.4,3.1,9.9s0.3,0.9,0.8,1c4.2,0.8,5.3,1.9,6.1,6.1c0.1,0.5,0.5,0.8,1,0.8 S11.9,17.4,12,17z" fill="#fff"/> <path d="M22,24c-2.8-0.6-3.4-1.2-4-4c-0.1-0.5-0.5-0.8-1-0.8s-0.9,0.3-1,0.8c-0.6,2.8-1.2,3.4-4,4c-0.5,0.1-0.8,0.5-0.8,1 s0.3,0.9,0.8,1c2.8,0.6,3.4,1.2,4,4c0.1,0.5,0.5,0.8,1,0.8s0.9-0.3,1-0.8c0.6-2.8,1.2-3.4,4-4c0.5-0.1,0.8-0.5,0.8-1 S22.4,24.1,22,24z" fill="#fff"/> <path d="M29.2,14c-2.2-0.4-2.7-0.9-3.1-3.1c-0.1-0.5-0.5-0.8-1-0.8c-0.5,0-0.9,0.3-1,0.8c-0.4,2.2-0.9,2.7-3.1,3.1 c-0.5,0.1-0.8,0.5-0.8,1s0.3,0.9,0.8,1c2.2,0.4,2.7,0.9,3.1,3.1c0.1,0.5,0.5,0.8,1,0.8c0.5,0,0.9-0.3,1-0.8 c0.4-2.2,0.9-2.7,3.1-3.1c0.5-0.1,0.8-0.5,0.8-1S29.7,14.1,29.2,14z" fill="#fff"/> <path d="M5.7,22.3C5.4,22,5,21.9,4.6,22.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3C4,22.7,4,22.9,4,23s0,0.3,0.1,0.4 c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2C4.7,24,4.9,24,5,24c0.1,0,0.3,0,0.4-0.1s0.2-0.1,0.3-0.2 c0.1-0.1,0.2-0.2,0.2-0.3C6,23.3,6,23.1,6,23s0-0.3-0.1-0.4C5.9,22.5,5.8,22.4,5.7,22.3z" fill="#fff"/> <path d="M28,7c0.3,0,0.5-0.1,0.7-0.3C28.9,6.5,29,6.3,29,6s-0.1-0.5-0.3-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.8,0 c-0.1,0-0.2,0.1-0.3,0.2C27.1,5.5,27,5.7,27,6c0,0.3,0.1,0.5,0.3,0.7C27.5,6.9,27.7,7,28,7z" fill="#fff"/>
      </svg>
      </div>
      


      <Modal
        show={open}
        onClose={() => setOpen(false)}
        className="dark:bg-black"
      >
        <Modal.Header className="dark:bg-black dark:text-white border-b border-black dark:border-white">
          Regenerate Topic: {jsonData[mainTopic][topicIndex].title}
        </Modal.Header>
        
        <Modal.Body className="dark:bg-black">
          <div className="space-y-6">
            <p className="text-gray-600 dark:text-gray-300">
              Enter additional context or requirements for regenerating this topic's subtopics
            </p>
            <TextInput
              value={userPrompt}
              onChange={(e) => setUserPrompt(e.target.value)}
              placeholder="E.g., Make it more practical, Add more technical details..."
              className="w-full"
            />
          </div>
        </Modal.Body>
        
        <Modal.Footer className="dark:bg-black border-t border-black dark:border-white">
          <div className="flex justify-end gap-2 w-full">
            <Button
              color="gray"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRegenerate}
              disabled={processing || !userPrompt}
              style={{ 
                backgroundImage: 'linear-gradient(147deg, #880caa, #008da6)',
                color: 'white'
              }}
            >
              {processing && (
                <AiOutlineLoading className="h-4 w-4 animate-spin mr-2" />
              )}
              Regenerate
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegenerateTopicDialog;